import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    ForgotPasswordType,
    SetNewPasswordType,
    SetPinType,
    SigninType,
    SignupType,
    UpdateUserPayload,
    ValidatePinType,
    VerifyOtpType,
} from "../../../types/auth.types";
import {
    fetchUserApi,
    forgotPasswordApi,
    logoutApi,
    setNewPasswordApi,
    setPinApi,
    signinApi,
    signupApi,
    validatePinApi,
    validateSessionApi,
    verifyOtpApi,
    updateUserApi,
    changePasswordApi,
} from "./auth.api";

export const signinThunk = createAsyncThunk(
    "auth/",
    async (payload: SigninType, { rejectWithValue }) => {
        try {
            const response = await signinApi(payload);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const validatePinThunk = createAsyncThunk(
    "auth/",
    async (payload: ValidatePinType, { rejectWithValue }) => {
        try {
            const response = await validatePinApi(payload);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchUserThunk = createAsyncThunk(
    "auth/getUser",
    async (payload: any, { rejectWithValue }) => {
        try {
            const response = await fetchUserApi();
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const updateUserThunk = createAsyncThunk(
    "auth/updateUser",
    async (payload: UpdateUserPayload, { rejectWithValue }) => {
        const { data, id } = payload;
        try {
            const response = await updateUserApi(data, id);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const validateAuthThunk = createAsyncThunk(
    "auth/validateAuth",
    async (payload: null, { rejectWithValue }) => {
        try {
            const response = await validateSessionApi();
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const signupThunk = createAsyncThunk(
    "auth/signup",
    async (payload: SignupType) => {
        const response = await signupApi(payload);
        return response;
    }
);

export const setPinThunk = createAsyncThunk(
    "auth/setPin",
    async (payload: SetPinType) => {
        const response = await setPinApi(payload);
        return response;
    }
);

export const forgotPasswordThunk = createAsyncThunk(
    "auth/forgotPassword",
    async (payload: ForgotPasswordType, { rejectWithValue }) => {
        try {
            const response = await forgotPasswordApi(payload);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const verifyOtpThunk = createAsyncThunk(
    "auth/verifyOtp",
    async (payload: VerifyOtpType) => {
        const response = await verifyOtpApi(payload);
        return response;
    }
);

export const setNewPasswordThunk = createAsyncThunk(
    "auth/setNewPassword",
    async (payload: SetNewPasswordType) => {
        const response = await setNewPasswordApi(payload);
        return response;
    }
);

export const logoutThunk = createAsyncThunk(
    "auth/logout",
    async (payload: null, { rejectWithValue }) => {
        try {
            const response = await logoutApi();
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const changePasswordThunk = createAsyncThunk(
    "auth/changePassword",
    async (payload: SetNewPasswordType, { rejectWithValue }) => {
        try {
            const response = await changePasswordApi(payload);

            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
