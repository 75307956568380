import { businessInstance } from "../../../axios";
import {
    CreateBusinessType,
    PointOfSaleType,
    SingleBusinessType,
} from "../../../types/business.types";

export const createOrUpdateBusinessAPI = async (
    payload: CreateBusinessType
) => {
    try {
        const url = "business-profile";
        const res = await businessInstance.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getBusinessAPI = async (id: string) => {
    try {
        const url = "business-profile/mybusinesses";
        const res = await businessInstance.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getBusinessByIdAPI = async (
    id: string
): Promise<SingleBusinessType> => {
    try {
        const url = `business-profile/${id}`;
        const res = await businessInstance.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const createPointOfSaleAPI = async (
    payload: PointOfSaleType,
    businessProfileId: string
) => {
    try {
        const url = "salespoint";
        const res = await businessInstance.post(url, payload, {
            headers: {
                businessProfileId,
            },
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getPointOfSaleAPI = async (businessProfileId: string) => {
    try {
        const url = "salespoint";
        const res = await businessInstance.get(url, {
            headers: {
                businessProfileId,
            },
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const createCashierAPI = async (
    payload: PointOfSaleType,
    businessProfileId: string
) => {
    try {
        const url = "salespoint/cashiers";
        const res = await businessInstance.get(url, {
            headers: {
                businessProfileId,
            },
        });

        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getCashierAPI = async (
    businessProfileId: string,
    salesPointId: string
) => {
    try {
        const url = `salespoint/${salesPointId}/cashiers`;
        const res = await businessInstance.get(url, {
            headers: {
                businessProfileId,
            },
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};
