import React from "react";
import dateToISO from "../../../../utils/dateToISO";

type FloatingLabelProps = {
    name: string;
    value: string | number;
    label?: string;
    type?: string;
    required?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
    error?: string;
    status?: string;
    disabled?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
    labelStyle?: string;
};

const FloatingLabelInput = ({
    name,
    value,
    label = "Input Label",
    type = "text",
    required,
    onChange,
    onFocus,
    error = "",
    status = "",
    disabled,
    inputRef,
    labelStyle = "",
}: FloatingLabelProps) => (
    <div className="relative z-0 w-full my-5 group font-avenirBook">
        <input
            className="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[2px] border-[#101828]/10 appearance-none focus:outline-none focus:ring-0 focus:border-blue-300 peer"
            id={name}
            type={type}
            onChange={onChange}
            onFocus={onFocus}
            name={name}
            value={value}
            required={required}
            disabled={disabled}
            max={type === "date" ? dateToISO(new Date()) : ""}
            ref={inputRef}
        />
        <label
            htmlFor={name}
            className={`absolute text-sm text-gray-500 pl-2 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-500  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${labelStyle}`}
        >
            {label || "Input Label"}
            {required && <span className="text-red-600 ">*</span>}
        </label>
        {error && (
            <p className="mt-2 text-xs text-red-600 ">
                <span className="font-medium">{error}</span>
            </p>
        )}
        {status && (
            <p className="mt-2 text-sm text-slate-600 ">
                <span className="font-light">{status}</span>
            </p>
        )}
    </div>
);

export default FloatingLabelInput;
