import React from "react";
import voidImage from "../../../../../assets/svg/void.svg";
import { MainButton } from "../../../../../components/elements/Button";

const NoTransactionHistory = () => (
    <div className="flex flex-col items-center justify-center my-10">
        <img src={voidImage} alt="No transaction" className="w-50" />
        <div className="my-3 text-xl font-extrabold text-black font-gilroyBold">
            You have no transaction yet
        </div>
        <div className="mb-3 text-black font-avenirBook">
            Make you first transaction and we will keep the record here for you
        </div>
        <MainButton>Fund Wallet</MainButton>
    </div>
);

export default NoTransactionHistory;
