import axios from "../../../axios";
import {
    ForgotPasswordType,
    SetNewPasswordType,
    SetPinType,
    SigninType,
    SignupType,
    GenericType,
    ValidatePinType,
    CreatePinType,
    UpdateUserType,
    UpdatePinType,
    VerifyOtpType,
    ResetPinType,
} from "../../../types/auth.types";

export const signinApi = async (payload: SigninType) => {
    try {
        const url = "/login/";
        const headers = {
            "App-Version": "web",
        };
        const res = await axios.post(url, payload, { headers });
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const createPinApi = async (payload: CreatePinType) => {
    try {
        const url = "users/bvn-trans-pin/";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const fetchUserApi = async () => {
    try {
        const url = "user-info/?for=user";
        const response = await axios.get(url);
        return response.data.data;
    } catch (err) {
        throw err;
    }
};

export const updateUserApi = async (payload: UpdateUserType, id: string) => {
    try {
        const url = `users/${id}/`;
        const response = await axios.post(url, payload);

        return response.data;
    } catch (error: any) {
        throw error;
    }
};

export const validatePinApi = async (payload: ValidatePinType) => {
    try {
        const url = "/users/validatepin/";
        const response = await axios.post(url, payload);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const validateSessionApi = async () => {
    try {
        const url = "/users/validateauth/";
        const response = await axios.get(url);
        return response.data.data;
    } catch (err) {
        throw err;
    }
};

export const resendPhoneVerificationApi = async (payload: any) => {
    try {
        const url = "/verify-number/";
        const response = await axios.post(url, payload);
        return response.data.data;
    } catch (err) {
        throw err;
    }
};

export const verifyKYCApi = async () => {
    try {
        const url = "/verify-user/";
        const response = await axios.get(url);
        return response.data.data;
    } catch (err) {
        throw err;
    }
};

export const verifyBVNApi = async (payload: any) => {
    try {
        const url = "/verify-user/";
        const response = await axios.post(url, payload);
        return response.data.data;
    } catch (err) {
        throw err;
    }
};
export const verifyNINApi = async (payload: any) => {
    try {
        const url = "/verify-user/";
        const response = await axios.post(url, payload);
        return response.data.data;
    } catch (err) {
        throw err;
    }
};

export const verifyBVNNumberApi = async (params: any) => {
    try {
        const url = "/verify-number/";
        const response = await axios.get(url, { params });
        return response.data.data;
    } catch (err) {
        throw err;
    }
};

export const signupApi = async (payload: SignupType) => {
    try {
        const url = "users/provision/";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const updatePinApi = async (payload: UpdatePinType) => {
    try {
        const url = "/users/pinchange/";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};
export const setPinApi = async (payload: SetPinType) => {
    try {
        const url = "";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const forgotPasswordApi = async (payload: ForgotPasswordType) => {
    const { phone } = payload;
    try {
        const url = "resettoken/?for=password";
        const res = await axios.post(url, { phone });
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const forgotPinApi = async (payload: GenericType) => {
    try {
        const url = "/resettoken/?for=pin";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const verifyOtpApi = async (payload: VerifyOtpType) => {
    try {
        const url = `users/verify-token/?phone=${payload.phone}&token=${payload.token}`;
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const setNewPasswordApi = async (payload: SetNewPasswordType) => {
    try {
        const url = "";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const logoutApi = async () => {
    try {
        const url = "/logout/";
        const res = await axios.post(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const changePasswordApi = async (payload: SetNewPasswordType) => {
    try {
        const url = "users/passwordchange/";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const resetPinApi = async (payload: ResetPinType) => {
    try {
        const url = "users/resetpin/";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};
