import React from "react";
import { HEXColor } from "../Checkout";

type ProgressBarProps = {
    size: number;
    color?: HEXColor;
};

const ProgressBar = ({ size, color = "#1FB649" }: ProgressBarProps) => (
    <div className="step__indicator">
        {/* this paragraph is hidden, so that tailwind can generate the utility color */}
        <p className="hidden bg-[#1FB649]" />
        <div className="w-full bg-[#1FB649]/10 rounded-full h-2.5">
            <div
                className={` h-2.5 rounded-full bg-[${color}]`}
                style={{ width: `${size}%` }}
            />
        </div>
    </div>
);

export default ProgressBar;
