import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "rsuite/dist/rsuite.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./assets/css/index.css";
import "./assets/css/tailwind.css";
import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from "./components/hoc/ErrorBoundary/index";
// import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./redux/store";
import KickOff from "./routes";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <KickOff />
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
