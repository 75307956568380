import { createSlice } from "@reduxjs/toolkit";
import { signinThunk } from "./AuthThunks";

type StateType = {
    user: object;
    profile: object;
    error: object;
    loading: boolean;
    hasError: boolean | null;
};

const initialState = {
    user: {},
    profile: {},
    error: {},
    hasError: null,
    loading: false,
} as StateType;

const authSlice = createSlice({
    name: "auth",
    initialState,

    reducers: {
        isPending: (state: any) => {
            state.loading = true;
        },
        isRejected: (state: any, action: any) => {
            state.loading = false;
            state.hasError = true;
            state.error = action.payload;
        },
        isFulfilled: (state: any, action: any) => {
            state.loading = false;
            state.user = action.payload;
        },
        setProfile(state: any, action: any) {
            state.loading = false;
            state.profile = action.payload;
        },
        logout(state: any) {
            state.loading = false;
            state.user = {};
        },
    },
    extraReducers: {
        [signinThunk.pending.toString()]: state => {
            state.loading = true;
        },
        [signinThunk.fulfilled.toString()]: (state, action) => {
            state.user = action.payload.data;
            state.loading = false;
        },
        [signinThunk.rejected.toString()]: state => {
            state.loading = false;
            state.hasError = true;
        },
    },
});

const { actions: authActions, reducer: authReducer } = authSlice;
export { authActions, authReducer };
