import axios from "axios";
import store from "store";

const isDev = process.env.NODE_ENV === "development";
const isProduction = process.env.NODE_ENV === "production";

const mainBaseUrl = isProduction ? process.env.REACT_APP_API_URL : "/main_api";
const walletUrl = isProduction
    ? process.env.REACT_APP_WALLET_URL
    : "/wallet_api";
const communityBaseUrl = isProduction
    ? process.env.REACT_APP_COMMUNITY_URL
    : "/communities_api";
const businessBaseUrl = isProduction
    ? process.env.REACT_APP_BUSINESS_URL
    : "/business_api";

const instance = axios.create({
    baseURL: mainBaseUrl,
    withCredentials: true,
});

const walletInstance = axios.create({
    baseURL: walletUrl,
    withCredentials: true,
});

const communityInstance = axios.create({
    baseURL: communityBaseUrl,
    withCredentials: true,
});

const businessInstance = axios.create({
    baseURL: businessBaseUrl,
});

instance.interceptors.request.use(
    (config: any) => {
        config.headers["Content-Type"] = "application/json";
        if (isDev) {
            config.headers["isLocalhost"] = "true";
        }
        return config;
    },
    (error: any) => Promise.reject(error)
);

walletInstance.interceptors.request.use(
    (config: any) => {
        config.headers["Content-Type"] = "application/json";
        if (isDev) {
            config.headers["isLocalhost"] = "true";
        }
        return config;
    },
    (error: any) => Promise.reject(error)
);

communityInstance.interceptors.request.use(
    (config: any) => {
        const user = store.get("data");
        if (typeof user !== "undefined") {
            config.headers["Content-Type"] = "application/json";
            config.headers["accessToken"] = user["Access-Token"];
            config.headers.userId = user.id;
            if (isDev) {
                config.headers["isLocalhost"] = "true";
            }
        } else {
            config.headers["Content-Type"] = "application/json";
            if (isDev) {
                config.headers["isLocalhost"] = "true";
            }
        }

        return config;
    },
    (error: any) => Promise.reject(error)
);

businessInstance.interceptors.request.use(
    (config: any) => {
        const user = store.get("data");
        if (typeof user !== "undefined") {
            config.headers["Content-Type"] = "application/json";
            config.headers["accessToken"] = user["Access-Token"];
            config.headers.userId = user.id;
            if (isDev) {
                config.headers["isLocalhost"] = "true";
            }
        } else {
            config.headers["Content-Type"] = "application/json";
            if (isDev) {
                config.headers["isLocalhost"] = "true";
            }
        }

        return config;
    },
    (error: any) => Promise.reject(error)
);

const successHandler = (response: any) => response;
const errorHandler = (error: any) => Promise.reject(error);

instance.interceptors.response.use(
    (response: any) => successHandler(response),
    (error: any) => {
        if (error.response.status === 401) {
            store.clearAll();
            // return window.location.replace("/");
        }

        return errorHandler(error);
    }
);

walletInstance.interceptors.response.use(
    (response: any) => successHandler(response),
    (error: any) => {
        if (error.response.status === 401) {
            store.clearAll();
            return window.location.replace("/");
        }

        return errorHandler(error);
    }
);

communityInstance.interceptors.response.use(
    (response: any) => {
        if (
            !response.data.successful &&
            response.data.message === "Your session has expired, Please login."
        ) {
            store.clearAll();
            return window.location.replace("/");
        }
        if (!response.data.successful) {
            return Promise.reject(response.data);
        }

        return successHandler(response);
    },
    (error: any) => errorHandler(error)
);

businessInstance.interceptors.response.use(
    (response: any) => successHandler(response),
    (error: any) => errorHandler(error)
);

export default instance;
export { walletInstance, communityInstance, businessInstance };
