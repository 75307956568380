/* eslint-disable react/no-array-index-key */
import React, { useId } from "react";
import { useSelector } from "react-redux";
import formatDate from "../../../utils/formatDate";
import formatWord from "../../../utils/formatWord";
import numberFormat from "../../../utils/numberFormat";

import imgLola from "../../../assets/images/logo/lola-logo.png";

/* eslint-disable react/react-in-jsx-scope */
// const { Column, HeaderCell, Cell } = Table;

const Row = ({ title, value }: { title: string; value: any }) => (
    <div className="flex items-center justify-between py-2 text-xs font-semibold sm:text-sm">
        <div className="text-left">{title}:</div>
        <div className="text-right">{value}</div>
    </div>
);

type Props = {
    forwardRef?: React.RefObject<HTMLDivElement>;
    data?: any;
    dates?: { from: string; to: string };
};

const StatementOfAccount = ({ forwardRef, data, dates }: Props) => {
    const id = useId();
    const user = useSelector((state: any) => state.auth.user);

    return (
        <section className="flex flex-col w-full overflow-auto">
            <div
                className="flex flex-col min-w-[1240px] w-full p-3"
                ref={forwardRef}
            >
                <div className="flex flex-col items-center">
                    <img
                        src={imgLola}
                        alt="alt"
                        className="w-auto h-16 object-contain"
                    />
                </div>
                <header>
                    <h4 className="text-xl font-extrabold">{`${user.firstName} ${user.lastName}`}</h4>
                </header>
                <main>
                    <div className="w-full my-8 ">
                        <h5 className="text-lg text-center uppercase font-semibold">
                            Statement of Account
                        </h5>
                        <p className="py-2 text-sm border-black border-y-4">
                            <span>{dates?.from}</span> -{" "}
                            <span>{dates?.to}</span>
                        </p>
                    </div>
                    <div className="w-5/12 ">
                        <h5 className="p-2 font-semibold bg-gray-200">
                            Account Summary
                        </h5>
                        <section className="p-2">
                            {Object.entries({
                                "Opening Balance": data.openingBalance,
                                "Total Debit": data.debitTotal,
                                "Total Credit": data.creditTotal,
                                "Closing Balance": data.closingBalance,
                            }).map(d => (
                                <Row
                                    title={formatWord(d[0])}
                                    value={`₦ ${numberFormat(d[1])}`}
                                    key={id}
                                />
                            ))}
                            <div className="">
                                <Row
                                    title="Number of Transactions"
                                    value={`${data.numberOfTransactions}`}
                                />
                            </div>
                            <div className="pt-1 mt-4 border-t-2 border-black">
                                <Row
                                    title="Total"
                                    value={`₦ ${numberFormat(
                                        data.totalVolume
                                    )}`}
                                />
                            </div>
                        </section>
                    </div>
                    <section className="my-8">
                        <table className="w-full text-left">
                            <thead className="bg-[#101828] text-white h-12">
                                <tr>
                                    {[
                                        { id: 0, title: "Date" },
                                        { id: 1, title: "Reference" },
                                        { id: 2, title: "Description" },
                                        { id: 3, title: "Credit(₦)" },
                                        { id: 4, title: "Debit(₦)" },
                                        { id: 5, title: "Balance" },
                                    ].map(d => (
                                        <th className="px-2 text-sm">
                                            {d.title}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.transactions.map((transaction: any) => (
                                    <tr className="h-16 border-b-2 pr-2 !important text-sm">
                                        <td className="pl-2 text-xs">
                                            {formatDate(
                                                transaction.date_created
                                            )}
                                        </td>
                                        <td className="w-2/12 pl-2 text-xs">
                                            {transaction.transactionReference}
                                        </td>
                                        <td className="w-5/12 pl-2 text-xs">
                                            {`${transaction.description}`}
                                        </td>
                                        <td className="w-1/12 pl-2 font-semibold">
                                            {transaction.type === "debit"
                                                ? numberFormat(
                                                      transaction.amount
                                                  )
                                                : null}
                                        </td>
                                        <td className="w-1/12 pl-2 font-semibold">
                                            {transaction.type === "credit"
                                                ? numberFormat(
                                                      transaction.amount
                                                  )
                                                : null}
                                        </td>
                                        <td className="pl-2 font-semibold w-fit">
                                            {numberFormat(
                                                transaction.balanceAfter
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>
                </main>
            </div>
        </section>
    );
};

export default StatementOfAccount;
