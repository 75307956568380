import React, { ReactNode } from "react";
import { FaTimes } from "react-icons/fa";
import ProgressBar from "../ProgressBar/ProgressBar";
import { HEXColor } from "../Checkout";

type DrawerProps = {
    open: boolean;
    children: ReactNode | ReactNode[];
    title: string;
    handleClose: () => void;
    description?: string;
    hasProgress?: boolean;
    progressWidth?: any;
    progressColor?: HEXColor;
    progressCount?: { lowerBound: number; upperBound: number };
};

const DrawerPanel = ({
    open,
    title,
    children,
    handleClose,
    description = "",
    hasProgress = false,
    progressWidth = 25,
    progressColor = "#009FF5",
    progressCount,
}: DrawerProps) => (
    <>
        <div
            data-testid="drawer-panel"
            className={
                open
                    ? "h-screen bg-white p-5 z-50 fixed top-0 bottom-0 right-0 left-auto w-80 sm:w-96 overflow-x-hidden overflow-y-auto"
                    : "hidden"
            }
        >
            <div className="flex justify-end">
                <button
                    type="button"
                    onClick={handleClose}
                    aria-label="close drawer panel"
                >
                    <FaTimes size={20} />
                </button>
            </div>
            {hasProgress && (
                <div className="my-6 border-0">
                    {progressCount && (
                        <div className="font-avenirBook flex text-base">
                            {`${progressCount.lowerBound}/${progressCount.upperBound}`}
                        </div>
                    )}
                    <ProgressBar color={progressColor} size={progressWidth} />
                </div>
            )}
            <div className="font-gilroyBold font-extrabold text-lg sm:text-xl text-[#101828] pb-1">
                {title}
            </div>
            <div className="text-sm font-avenirBook pb-5 text-[#667085]">
                {description}
            </div>
            {children}
        </div>

        {/* overlay */}
        <div
            className={
                open
                    ? "fixed z-40 top-0 bottom-0 right-0 left-0 overflow-hidden bg-black opacity-40 h-full"
                    : "hidden"
            }
            onClick={handleClose}
            role="none"
        />
    </>
);

export default DrawerPanel;
