import React from "react";
import "./button.css";
import { Button } from "rsuite";

export const MainButton = ({ children, ...props }: any) => (
    <Button
        className="font-medium lola__btn font-avenirBook hover:text-white hover:underline"
        {...props}
    >
        {children}
    </Button>
);

export const DrawerButton = ({ children, ...props }: any) => (
    <button
        type="button"
        className="font-gilroyBold bg-[#009FF5] w-full p-2.5 my-5 text-white text-sm sm:text-md rounded-md hover:bg-[#008ff5]"
        {...props}
    >
        {children}
    </button>
);

export const CustomButton = ({ children, buttonStyle, ...props }: any) => (
    <button
        type="button"
        className={`py-2 px-4 tracking-wide transition-all duration-200 ${buttonStyle}`}
        {...props}
    >
        {children}
    </button>
);
