import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {},
    transactions: [],
    loading: false,
    transactionLoading: false,
    error: null,
};

const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {
        setWalletData: (state: any, action: any) => {
            state.data = action.payload;
        },
        setWalletTransactions: (state: any, action: any) => {
            state.transactions = action.payload;
        },
        setWalletLoading: (state: any) => {
            state.loading = true;
        },
        setWalletTransactionLoading: (state: any) => {
            state.transactionLoading = true;
        },
        setWalletError: (state: any, action: any) => {
            state.error = action.payload;
        },
        setTransactionError: (state: any, action: any) => {
            state.error = action.payload;
        },
    },
    extraReducers: {},
});

const { actions: walletActions, reducer: walletReducer } = walletSlice;
export { walletActions, walletReducer };
