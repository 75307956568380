import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PageHelmet from "../../../../components/blocks/PageHelmet/PageHelmet";
import Loading from "../../../../components/elements/Loading/Loading";
import NoImg from "../../../../assets/images/logo/lola-logo.png";
import NoLogo from "../../../../assets/images/logo/lola-min.png";
import { CustomButton } from "../../../../components/elements/Button";
import CreatePOS from "./CreatePOS";
import PosCard from "./PosCard";
import TransactionHistory from "./TransactionHistory";
import { getBusinessByIdAPI } from "../../../../redux/slices/business/business.api";
import { CustomErrorToast } from "../../../../components/elements/Alerts";
import { SingleBusinessType } from "../../../../types/business.types";
import BusinessDetailsDrawer from "./BusinessDetailsDrawer";

const initialModalState = {
    create: false,
    transaction: false,
    sales: false,
};

const BusinessDetails = () => {
    const { state }: any = useLocation();

    const [showModal, setShowModal] = useState<{ [key: string]: boolean }>(
        initialModalState
    );
    const [loading, setLoading] = useState<boolean>(false);

    const handleCloseModal = () => {
        setShowModal(initialModalState);
    };

    const handleShowModal = (choice: string) => {
        setShowModal({ ...showModal, [choice]: !showModal[choice] });
    };

    const [singleBusiness, setSingleBusiness] = useState<
        SingleBusinessType | Record<string, string>
    >({});

    const getBusinessDetails = async () => {
        setLoading(true);
        try {
            const res = await getBusinessByIdAPI(state?.id);
            setSingleBusiness(res);
            setLoading(false);
        } catch (err) {
            CustomErrorToast(err);
        }
    };

    useEffect(() => {
        getBusinessDetails();
        return () => {
            setSingleBusiness({});
        };
    }, []);

    return (
        <>
            <PageHelmet title="Business Details" />
            {loading ? (
                <div className="flex items-center justify-center">
                    <Loading size="lg" />
                </div>
            ) : (
                <div className="container pr-4 mx-auto lg:w-10/12">
                    <div className="relative flex flex-col items-center">
                        <div className="w-full">
                            <div className="relative h-40 border-transparent rounded-lg">
                                <img
                                    src={NoImg}
                                    alt=""
                                    className="object-scale-down bg-[#D6DFE4] absolute w-full h-40 rounded-lg "
                                />
                            </div>
                            <div className="relative block w-32 h-32 border-4 border-[#f5f5f5] rounded-full bottom-16 left-10 circular--landscape">
                                <img
                                    src={NoLogo}
                                    alt=""
                                    className="object-scale-down bg-[#D6DFE4] absolute  w-full h-full"
                                />
                            </div>
                        </div>
                        <div className="self-end text-sm underline text-[#F75500] cursor-pointer relative bottom-24 w-fit">
                            <button
                                type="button"
                                onClick={() => handleShowModal("sales")}
                                className="underline text-[#F75500] cursor-pointer"
                            >
                                Edit
                            </button>
                            {showModal.sales && (
                                <BusinessDetailsDrawer
                                    open
                                    toggleModal={handleCloseModal}
                                />
                            )}
                        </div>
                        <div className="w-full space-y-6">
                            <h2 className="text-xl font-medium">
                                {singleBusiness?.businessProfileName}
                            </h2>
                            <p className="text-gray-500">
                                {singleBusiness?.businessProfileAbout}
                            </p>
                            <CustomButton
                                buttonStyle="bg-gradient-to-tr from-[#995C12] to-[#995C12] text-[#fff] rounded-md shadow-md hover:from-[#cd7b18] hover:to-[#cd7b18] px-10 py-4 text-sm font-semibold"
                                onClick={() => handleShowModal("transaction")}
                            >
                                View Transaction History
                            </CustomButton>
                            {showModal.transaction && (
                                <TransactionHistory
                                    open
                                    toggleModal={handleCloseModal}
                                />
                            )}
                        </div>
                    </div>
                    <div className="pb-5 pt-5 space-y-10">
                        <div className="flex items-center p-6 sm:p-3 flex-wrap gap-auto justify-center mx-auto text-center  rounded-lg bg-[#F4F3F3] min-h-[115px]">
                            <section className="w-6/12 mb-8 sm:w-3/12 sm:mb-0 space-y-1">
                                <div className="text-[#585D69] text-xs">
                                    Total Balance
                                </div>
                                <div className="text-[#101828]  text-sm sm:text-base">
                                    N20,000
                                </div>
                            </section>
                            <section className="w-6/12 mb-8 sm:w-3/12 sm:mb-0 space-y-1">
                                <div className="text-[#585D69] text-xs">
                                    Total Transactions
                                </div>
                                <div className="text-[#101828]  text-sm sm:text-base">
                                    1000
                                </div>
                            </section>
                            <section className="w-6/12 mb-8 sm:w-3/12 sm:mb-0 space-y-1">
                                <div className="text-[#585D69] text-xs">
                                    Business Type
                                </div>
                                <div className="text-[#101828]  text-sm sm:text-base">
                                    {singleBusiness?.businessType}
                                </div>
                            </section>
                            <section className="w-6/12 mb-8 sm:w-3/12 sm:mb-0 space-y-1">
                                <div className="text-[#585D69] text-xs">
                                    Cashier Representatives
                                </div>
                                <div className="text-[#101828]  text-sm sm:text-base">
                                    20
                                </div>
                            </section>
                        </div>
                    </div>

                    <div className=" p-5 flex flex-wrap items-center justify-between gap-y-4">
                        <div>
                            <h3 className="text-lg font-semibold">
                                Point of Sales
                            </h3>
                            <span className="text-xs">
                                List of all the point of sales available
                            </span>
                        </div>
                        <CustomButton
                            buttonStyle="bg-gradient-to-tr from-[#995C12] to-[#995C12] text-[#fff] rounded-md shadow-md hover:from-[#cd7b18] hover:to-[#cd7b18] px-10 py-4 text-sm font-semibold"
                            onClick={() => handleShowModal("create")}
                        >
                            Create Point of Sale
                        </CustomButton>
                        {showModal.create && (
                            <CreatePOS
                                open
                                toggleModal={handleCloseModal}
                                businessProfileId={
                                    singleBusiness?._id
                                } /* eslint no-underscore-dangle: 0 */
                            />
                        )}
                    </div>

                    <div className="flex flex-col">
                        <PosCard
                            businessProfileId={
                                singleBusiness?._id
                            } /* eslint no-underscore-dangle: 0 */
                        />

                        <div className="flex flex-col p-5">
                            <h2 className="text-[#101828] text-2xl font-semibold">
                                Location
                            </h2>
                            <p>{singleBusiness?.businessProfileAddress}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default BusinessDetails;
