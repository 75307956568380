import React from "react";
import { Icon } from "@rsuite/icons";

const svg = React.forwardRef((props, ref) => (
    <svg
        {...props}
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.66667 8H7.33333V6.66667H4.66667V8ZM0 0V1.33333H12V0H0ZM2 4.66667H10V3.33333H2V4.66667Z"
            fill="black"
        />
    </svg>
));

const FilterIcon = ({ size, ...props }: { size: string; props: any } & any) => (
    <Icon as={svg} style={{ fontSize: size }} {...props} />
);

export default FilterIcon;
