import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CommunityType } from "../../../types/communities.types";

type TCommunitySliceState = {
    communities: CommunityType[];
};

const initialState: TCommunitySliceState = {
    communities: [],
};

const communitySlice = createSlice({
    name: "communities",
    initialState,
    reducers: {
        addCommunities: (state: any, { payload }: PayloadAction<any>) => {
            state.communities = payload;
        },
    },
});

const { actions: communityActions, reducer: communityReducer } = communitySlice;
export { communityActions, communityReducer };
