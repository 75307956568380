import React, { memo, useEffect, useState } from "react";
import NotFoundImg from "../../../../assets/images/png/notFound.png";
import NoImg from "../../../../assets/images/logo/lola-logo.png";
import NoLogo from "../../../../assets/images/logo/lola-min.png";
import { CustomErrorToast } from "../../../../components/elements/Alerts";
import { CustomButton } from "../../../../components/elements/Button";
import Loading from "../../../../components/elements/Loading/Loading";
import { getPointOfSaleAPI } from "../../../../redux/slices/business/business.api";
import { PointOfSaleType } from "../../../../types/business.types";
import SalesPoint from "./SalesPoint";

const initialModalState = {
    sales: false,
};

type Props = { businessProfileId: string };

const PosCard = memo(({ businessProfileId = "" }: Props) => {
    const [showModal, setShowModal] = useState<{ [key: string]: boolean }>(
        initialModalState
    );

    const [loading, setLoading] = useState<boolean>(false);

    const handleCloseModal = () => {
        setShowModal(initialModalState);
    };

    const handleShowModal = (choice: string) => {
        setShowModal({ ...showModal, [choice]: !showModal[choice] });
    };

    const [pointOfSale, setPointOfSale] = useState<PointOfSaleType[]>([]);

    const getPointOfSale = async () => {
        setLoading(true);
        try {
            const res = await getPointOfSaleAPI(businessProfileId);
            setPointOfSale(res);
        } catch (err) {
            CustomErrorToast(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPointOfSale();
        return () => {
            setPointOfSale([]);
        };
    }, []);

    function handlePayment() {
        throw new Error("Function not implemented.");
    }

    return (
        <div>
            {loading ? (
                <div className="container flex items-center justify-center mx-auto">
                    <Loading size="lg" />
                </div>
            ) : (
                <div>
                    {pointOfSale.length ? (
                        pointOfSale.map(pos => (
                            <div
                                key={pos?.name}
                                role="gridcell"
                                className="rs-col rs-col-lg-8 rs-col-md-12 rs-col-sm-12 rs-col-xs-24 p-4"
                            >
                                <div className="relative flex flex-col">
                                    <div className="w-full">
                                        <div className="relative h-40 border-transparent rounded-lg">
                                            <img
                                                src={NoImg}
                                                alt=""
                                                className="object-scale-down bg-[#D6DFE4] absolute w-full h-40 rounded-lg"
                                            />
                                        </div>
                                        <div className="relative block w-32 h-32 border-4 border-[#f5f5f5] rounded-full bottom-16 left-10 circular--landscape">
                                            <img
                                                src={NoLogo}
                                                alt=""
                                                className="object-scale-down bg-[#D6DFE4] absolute w-full h-full rounded-full"
                                            />
                                        </div>
                                    </div>
                                    <div className="self-end text-sm underline text-[#F75500] cursor-pointer relative bottom-24 w-fit">
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleShowModal("sales")
                                            }
                                            className="underline text-[#F75500] cursor-pointer"
                                        >
                                            View
                                        </button>
                                        {showModal.sales && (
                                            <SalesPoint
                                                open
                                                toggleModal={handleCloseModal}
                                            />
                                        )}
                                    </div>

                                    <div className="space-y-4">
                                        <div>
                                            <h2 className="text-xs md:text-base text-[#585D69]">
                                                Name
                                            </h2>
                                            <p className="text-lg font-semibold capitalize line-clamp-1">
                                                {pos?.name}
                                            </p>
                                        </div>
                                        <div>
                                            <h4 className="text-lg font-medium text-[#585D69]">
                                                About
                                            </h4>
                                            <p className="text-md text-[#585D69]">
                                                {pos?.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <CustomButton
                                    buttonStyle="bg-gradient-to-tr from-[#995C12] to-[#995C12] text-[#fff] rounded-md shadow-md hover:from-[#cd7b18] hover:to-[#cd7b18] px-10 py-4 text-sm font-semibold"
                                    onClick={() => handlePayment()}
                                >
                                    Receive Payment
                                </CustomButton>
                            </div>
                        ))
                    ) : (
                        <section className="container flex flex-col items-center justify-between pt-16 mx-auto gap-y-6">
                            <div className="">
                                <img src={NotFoundImg} alt="Not_Found" />
                            </div>
                            <div className="text-center">
                                <h1 className="text-xl font-bold text-black">
                                    You have no Point of Sale!
                                </h1>
                            </div>
                        </section>
                    )}
                </div>
            )}
        </div>
    );
});

export default PosCard;
