import { validateAll } from "indicative/validator";
import React, { SyntheticEvent, useState } from "react";
import {
    ErrorToast,
    SuccessToast,
} from "../../../../components/elements/Alerts";
import { CustomButton } from "../../../../components/elements/Button";
import DrawerPanel from "../../../../components/elements/DrawerPanel/DrawerPanel";
import FloatingLabelInput from "../../../../components/elements/Inputs/FloatingLabelInput";
import Loading from "../../../../components/elements/Loading/Loading";
import {
    createCashierAPI,
    createPointOfSaleAPI,
} from "../../../../redux/slices/business/business.api";
import { PointOfSaleType } from "../../../../types/business.types";

type Props = {
    open: boolean;
    toggleModal: () => void;
    businessProfileId: string;
};

type FormData = {
    name: string;
    description: string;
    phoneNumber: string;
};

const initialFormData: FormData = {
    name: "",
    description: "",
    phoneNumber: "",
};

const CreatePOS = ({ open, toggleModal, businessProfileId }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [error, setError] = useState<any>({});

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    };

    const processCreate = async () => {
        setLoading(true);
        const payload: PointOfSaleType = {
            name: formData.name,
            description: formData.description,
            cashierPhoneNumber: formData.phoneNumber,
        };
        try {
            const [pointOfSaleRes, cashierRes] = await Promise.all([
                createPointOfSaleAPI(payload, businessProfileId),
                createCashierAPI(payload, businessProfileId),
            ]);

            SuccessToast(pointOfSaleRes, cashierRes);
            setFormData(initialFormData);
            toggleModal();
        } catch (err) {
            ErrorToast(err);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();

        const rules = {
            name: "required",
            description: "required",
        };

        const messages = {
            "name.required": " Name is required",
            "description.required": "Description is required",
            "phoneNumber.required": "Phone Number is required",
        };

        validateAll(formData, rules, messages)
            .then(() => {
                processCreate();
            })
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });
    };

    return (
        <DrawerPanel
            open={open}
            handleClose={toggleModal}
            title="Create Sales Point"
            description="Setup point of sales"
        >
            <div className="flex flex-col justify-center items-center">
                <div className="relative w-[80px] h-[80px] rounded-full border-4 border-transparant">
                    <img
                        src=""
                        alt="avatar"
                        className="absolute w-full h-full rounded-full"
                    />
                </div>
                <p>Tap to set Logo</p>
            </div>
            <div className="text-[#101828]">
                <form className="space-y-6">
                    <div>
                        <FloatingLabelInput
                            label="Sales Point Name"
                            name="name"
                            type="text"
                            onChange={handleInputChange}
                            value={formData.name}
                            error={error.name}
                        />
                    </div>
                    <div>
                        <FloatingLabelInput
                            label="About POS"
                            name="description"
                            type="text"
                            onChange={handleInputChange}
                            value={formData.description}
                            error={error.description}
                        />
                    </div>
                    <div>
                        <h3>Create Cashier</h3>
                        <p className="text-sm font-avenirBook pb-5 text-[#667085]">
                            Invite sales representatives for your business
                        </p>
                    </div>
                    <div>
                        <FloatingLabelInput
                            label="Phone Number"
                            name="phoneNumber"
                            type="tel"
                            onChange={handleInputChange}
                            value={formData.phoneNumber}
                            error={error.phoneNumber}
                        />
                    </div>

                    <div className="text-center">
                        <CustomButton
                            buttonStyle="bg-[#009FF5] w-full p-2.5 my-4 text-white text-sm sm:text-md rounded hover:bg-[#008ff5]"
                            onClick={handleSubmit}
                        >
                            {loading ? (
                                <Loading size="xs" />
                            ) : (
                                "Create Sales Point"
                            )}
                        </CustomButton>
                    </div>
                </form>
            </div>
        </DrawerPanel>
    );
};

export default CreatePOS;
