import React, { useEffect, useState } from "react";
import { Dropdown } from "rsuite";
import { MdOutlineTrendingUp, MdOutlineTrendingDown } from "react-icons/md";
import { Link } from "react-router-dom";
import DrawerPanel from "../../../../components/elements/DrawerPanel/DrawerPanel";
import FilterIcon from "../../../../components/elements/icons/filter";
import Loading from "../../../../components/elements/Loading/Loading";
import NoTransactionHistory from "../dashboard/TransactionHistory/NoTransaction";

const filterOptions = [
    {
        name: "All",
        value: "all",
    },
    {
        name: "Sales Point",
        value: "sales_point",
    },
    {
        name: "Money Sent",
        value: "money_sent",
    },
    {
        name: "Money Received",
        value: "money_received",
    },
    {
        name: "Date",
        value: "date",
    },
    {
        name: "Cashier",
        value: "cashier",
    },
];

type Props = { open: boolean; toggleModal: () => void };

export const filterBtn = (props: any, ref: any) => (
    <button {...props} ref={ref} type="button" className="flex items-center">
        <FilterIcon />
        <span className="ml-2 text-black">Filter</span>
    </button>
);

const TransactionHistory = ({ open, toggleModal }: Props) => {
    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState<any>([]);
    const noTransactions = !loading && transactions.length < 1;

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setTransactions([
                {
                    id: 1,
                    type: "money_received",
                    amount: 10000,
                    date: "2021-01-01",
                    cashier: "John Paul",
                },
                {
                    id: 2,
                    type: "money_sent",
                    amount: 80000,
                    date: "2020-03-01",
                    cashier: "Peter Doe",
                },
                {
                    id: 3,
                    type: "money_received",
                    amount: 700000,
                    date: "2022-02-01",
                    cashier: "John Doe",
                },
                {
                    id: 4,
                    type: "sales_point",
                    amount: 50000,
                    date: "2023-03-01",
                    cashier: "John Balabu",
                },
            ]);
        }, 2000);
    }, []);

    return (
        <DrawerPanel
            open={open}
            handleClose={toggleModal}
            title="Transaction History"
            description="Access all transations in this business and it’s points
        of sales transaction details"
        >
            <div>
                <Dropdown
                    renderToggle={filterBtn}
                    placement="bottomStart"
                    size="lg"
                >
                    {filterOptions.map(option => (
                        <Dropdown.Item
                            key={option.value}
                            eventKey={option.value}
                        >
                            {option.name}
                        </Dropdown.Item>
                    ))}
                </Dropdown>
            </div>
            {loading ? (
                <div className="container flex items-center justify-center mx-auto">
                    <Loading size="md" />
                </div>
            ) : noTransactions ? (
                <NoTransactionHistory />
            ) : (
                <div className="mt-6">
                    {transactions.map((transaction: any) => (
                        <div
                            key={transaction.id}
                            className="flex justify-between items-center mb-4"
                        >
                            <div className="flex items-center ">
                                <div>
                                    {transaction.type === "money_received" ? (
                                        <div className="p-3 text-white bg-green-600 rounded-full w-fit">
                                            <MdOutlineTrendingUp className="text-white" />
                                        </div>
                                    ) : (
                                        <div className="p-3 text-white bg-red-600 rounded-full w-fit">
                                            <MdOutlineTrendingDown className="text-white" />
                                        </div>
                                    )}
                                </div>
                                <div className="p-2">
                                    <h6 className="font-semibold">
                                        {transaction.cashier}
                                    </h6>
                                    <p className="text-xs">
                                        ₦ {transaction.amount}
                                    </p>
                                </div>
                            </div>

                            <div className="text-sm underline text-[#009FF5] cursor-pointer w-fit">
                                <Link to="#">Export </Link>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </DrawerPanel>
    );
};

export default TransactionHistory;
