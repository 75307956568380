import React from "react";
import { useRoutes } from "react-router";
import loadable from "@loadable/component";
import StatementOfAccount from "../components/blocks/vend/StatementOfAccount";
import BusinessDetails from "../pages/appcore/user/business/BusinessDetails";

const Authenticate = loadable(
    () => import("../pages/auth/signin/Authenticate")
);
const AuthOutlet = loadable(
    () => import("../components/hoc/AuthOutlet/AuthOutlet")
);
const AutoDebit = loadable(() => import("../pages/appcore/user/autoDebit"));
const BillsAndPayment = loadable(
    () => import("../pages/appcore/user/billsandpayment")
);
const Business = loadable(() => import("../pages/appcore/user/business"));
const Community = loadable(() => import("../pages/appcore/user/community"));
const CommunityDetail = loadable(
    () => import("../pages/appcore/user/community/communitydetails/index")
);
const CommunityEvents = loadable(
    () => import("../pages/appcore/user/community/communitydetails/Events")
);

const CommunityMarketPlace = loadable(
    () => import("../pages/appcore/user/community/communitydetails/MarketPlace")
);

const CommunityProducts = loadable(
    () =>
        import(
            "../pages/appcore/user/community/communitydetails/CommunityProducts"
        )
);
const CompleteVerification = loadable(
    () => import("../pages/auth/forgotPassword/CompleteVerification")
);
const Dashboard = loadable(() => import("../pages/appcore/user/dashboard"));
const ForgotPassword = loadable(
    () => import("../pages/auth/forgotPassword/ForgotPassword")
);
const MainWrapper = loadable(() => import("../components/layout/MainWrapper"));
const Merchant = loadable(() => import("../pages/appcore/user/merchant"));
const NotFound = loadable(() => import("../pages/NotFound"));
const PinSetup = loadable(() => import("../pages/auth/pinSetup/PinSetup"));
const SetNewPassword = loadable(
    () => import("../pages/auth/forgotPassword/SetNewPassword")
);
const KycVerification = loadable(
    () => import("../pages/auth/kycVerification/index")
);
const Settings = loadable(() => import("../pages/appcore/user/Settings"));
const Signin = loadable(() => import("../pages/auth/signin/Signin"));
const Signout = loadable(() => import("../pages/appcore/Signout"));
const Signup = loadable(() => import("../pages/auth/signup/Signup"));
const TransactionHistory = loadable(
    () => import("../pages/appcore/user/dashboard/TransactionHistory")
);
const UserDashboard = loadable(
    () => import("../pages/appcore/user/dashboard/Wallet")
);
const Verification = loadable(
    () => import("../pages/auth/forgotPassword/Verification")
);

const AppRoutes = () => {
    const allRoutes = useRoutes([
        {
            path: "/",
            children: [
                {
                    path: "/signup",
                    element: <Signup />,
                },
                { element: <Signin />, index: true },
                { path: "/authenticate", element: <Authenticate /> },
                { path: "/forgot-password", element: <ForgotPassword /> },
                { path: "/verify", element: <Verification /> },
                {
                    path: "/verify-otp/:phone",
                    element: <CompleteVerification />,
                },
                { path: "/kyc-verification", element: <KycVerification /> },
                { path: "/set-password", element: <SetNewPassword /> },
                { path: "/set-pin", element: <PinSetup /> },
                { path: "statement", element: <StatementOfAccount /> },
            ],
        },
        {
            element: <AuthOutlet />,
            children: [
                {
                    path: "/",
                    element: <MainWrapper />,
                    children: [
                        { path: "dashboard", element: <Dashboard /> },
                        {
                            path: "wallet",
                            element: <UserDashboard />,
                        },
                        {
                            path: "pay-bills",
                            element: <BillsAndPayment />,
                        },
                        {
                            path: "merchant",
                            element: <Merchant />,
                        },
                        {
                            path: "business",
                            element: <Business />,
                        },
                        {
                            element: <BusinessDetails />,
                            path: "business/:id",
                        },
                        {
                            path: "communities",
                            children: [
                                {
                                    element: <Community />,
                                    index: true,
                                },
                                {
                                    path: ":id",
                                    children: [
                                        {
                                            element: <CommunityDetail />,
                                            index: true,
                                        },
                                        {
                                            element: <CommunityEvents />,
                                            path: "events",
                                        },
                                        {
                                            element: <CommunityMarketPlace />,
                                            path: "marketplace",
                                        },
                                        {
                                            element: <CommunityProducts />,
                                            path: "products",
                                        },
                                    ],
                                },
                            ],
                        },

                        {
                            path: "settings",
                            element: <Settings />,
                        },
                        {
                            path: "/transactions",
                            element: <TransactionHistory />,
                        },
                        {
                            path: "/auto-debit",
                            element: <AutoDebit />,
                        },
                    ],
                },
            ],
        },
        { path: "*", element: <NotFound /> },
        { path: "/logout", element: <Signout /> },
    ]);

    return allRoutes;
};

export default AppRoutes;
