import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DrawerPanel from "../../../../components/elements/DrawerPanel/DrawerPanel";
import { CustomButton } from "../../../../components/elements/Button";
import Loading from "../../../../components/elements/Loading/Loading";
import { getBusinessByIdAPI } from "../../../../redux/slices/business/business.api";
import { CustomErrorToast } from "../../../../components/elements/Alerts";
import { SingleBusinessType } from "../../../../types/business.types";
import NoLogo from "../../../../assets/images/logo/lola-min.png";

type Props = { open: boolean; toggleModal: () => void };

const BusinessDetailsDrawer = ({ open, toggleModal }: Props) => {
    const { state }: any = useLocation();
    const [loading, setLoading] = useState<boolean>(false);

    const [singleBusiness, setSingleBusiness] = useState<
        SingleBusinessType | Record<string, string>
    >({});

    const getBusinessDetails = async () => {
        setLoading(true);
        try {
            const res = await getBusinessByIdAPI(state?.id);
            setSingleBusiness(res);
        } catch (err) {
            CustomErrorToast(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getBusinessDetails();
        return () => {
            setSingleBusiness({});
        };
    }, []);

    return (
        <DrawerPanel
            open={open}
            handleClose={toggleModal}
            title="Sales Point"
            description="Overview of your sales point"
        >
            <div className="flex flex-col justify-center items-center my-5">
                <div className="relative w-[80px] h-[80px] rounded-full border-4 border-transparant">
                    <img
                        src={
                            singleBusiness?.businessProfileLogoFilestoreId ||
                            NoLogo
                        }
                        alt="avatar"
                        className="absolute w-full h-full rounded-full"
                    />
                </div>
            </div>
            <div className="mb-5 space-y-5">
                <div>
                    <h3 className="text-gray-500  text-sm">Sales Point Name</h3>
                    <h4 className="text-[#101828] text-md">
                        {singleBusiness?.businessProfileName}
                    </h4>
                </div>
                <div>
                    <h3 className="text-gray-500 text-sm">About Sales Point</h3>
                    <h4 className="text-[#101828] text-md">
                        {singleBusiness?.businessProfileAbout}
                    </h4>
                </div>
                <div>
                    <h3 className="text-gray-500 text-sm">Location</h3>
                    <h4 className="text-[#101828] text-md ">
                        {singleBusiness?.businessProfileAddress}
                    </h4>
                </div>
            </div>

            <div className="mb-5">
                <hr />
            </div>

            {/* <div>
                <h3 className="text-[#101828]  text-md">
                    Cashier Representative
                </h3>
                <h4 className="text-gray-500 text-sm font-gilroyMedium">
                    Invite sales representatives for your business
                </h4>
            </div>
            <div className="flex items-center gap-4 p-2 cursor-pointer hover:bg-slate-100">
                <div className="rounded-full bg-[#009FF5] text-white  text-extrabold font-gilroyBold uppercase p-4 text-lg">
                    <h3>OA</h3>
                </div>
                <div className="w-40 md:block md:text-lg sm:text-base text-sm font-avenirBook">
                    <div className="text-[#585D69] text-base">
                        <h3>Olatunji Abayomi</h3>
                        <h3>09052503606</h3>
                    </div>
                </div>
                <button
                    type="button"
                    onClick={() => {}}
                    className="underline text-[#f44336] cursor-pointer"
                >
                    Delete
                </button>
            </div> */}

            <div className="text-center">
                <CustomButton
                    buttonStyle="bg-[#F75500] cursor-pointer w-full p-2.5 my-4 text-white text-sm sm:text-md rounded"
                    onClick=""
                >
                    {loading ? <Loading size="xs" /> : "Edit Sales Point"}
                </CustomButton>
            </div>
        </DrawerPanel>
    );
};

export default BusinessDetailsDrawer;
