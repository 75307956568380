import { combineReducers } from "@reduxjs/toolkit";
import { authReducer } from "./slices/auth/AuthSlice";
import { communityReducer } from "./slices/communities/communities.slice";
import { walletReducer } from "./slices/wallet/WalletSlice";

const rootReducer = combineReducers({
    auth: authReducer,
    communities: communityReducer,
    wallet: walletReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
