import React from "react";
import { Helmet } from "react-helmet";

type HelmetProps = {
    title?: string;
    description?: string;
};

const PageHelmet = ({
    title = "LOLA Finance",
    description = "",
}: HelmetProps) => (
    <Helmet title={title}>
        <meta name="description" content={description} />
    </Helmet>
);

export default PageHelmet;
